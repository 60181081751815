import React, { Component } from "react";
import populationData from "./populationData.json";
class CustomTooltip extends Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload } = this.props;
      let total = payload[0].value + payload[1].value;
      let race = payload[0].dataKey.split("_")[0];
      let averageOfficers =  payload[0].payload.averageOfficers
      return (
        <div
          style={{
            color: "black",
            background: "rgba(199, 199, 199, 0.97)",
            borderRadius: "5px",
            borderStyle: "solid",
            borderWidth: "thin",
            padding: "10px",
          }}
        >
          {race}:
          <br />
          Incidents: {total}
          <br />
          Incidents per 1000 Residents:{" "}
          {(
            1000 *
            (total / (+populationData[0].total * +populationData[0][race]))
          ).toFixed(2)}
          <br />
          Male: {payload[0].value}
          <br />
          Female: {payload[1].value}
          <br />
          {populationData[0][race] && (
            <>
              Percentage of Population:{" "}
              {(populationData[0][race] * 100).toFixed(2) + "% "}
              <br />
            </>
          )}
          Percentage of Incidents:{" "}
          {((total / this.props.total) * 100).toFixed(2) + "%"}
          <br />
          Average Officers Involved: {averageOfficers}
          <br />
        </div>
      );
    }

    return null;
  }
}

export default CustomTooltip;
