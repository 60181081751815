// src/App.js

import React from "react";
import { Router, Route, Switch } from "react-router-dom";

// New - import the React Router components, and the Profile page component

import history from "./utils/history";

import main from "./main";

function App() {
  return (
    <div className="App">
      {/* Don't forget to include the history module */}
      <Router history={history}>
        <Switch>
          <Route path="/" exact component={main} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
