import React, { Component } from "react";
import {
  Text
} from "recharts";
class CustomXlabel extends Component {
  render () {
    if(window.innerWidth>600){
      const {x, y, payload} = this.props;
       return <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">{payload.value}</Text>
    }
    return null;
  
}
}

export default CustomXlabel;
