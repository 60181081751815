import React, { Component } from "react";
import jstat from "jstat";
class CustomTooltipOfficers extends Component {
  render() {
    const { active } = this.props;
    if (active) {
      const { payload } = this.props;
      let total = payload[0].value;
      let officers = payload[1].value;
      return (
        <div
          style={{
            maxWidth: '300px',
            color: "black",
            background: "rgba(199, 199, 199, 0.97)",
            borderRadius: "5px",
            borderStyle: "solid",
            borderWidth: "thin",
            padding: "10px",
          }}
        >
          {officers} Officers had {total} Use Of Force Incidents
          <br />
          <br />
          {jstat
            .zscore(total, Object.values(this.props.officerDataRaw))
            .toFixed(2)}{" "}
          Standard Deviations from the mean 
          <br />
          (Based on officers that had incidents with this demographic
          during this time frame)
          <br />
        </div>
      );
    }

    return null;
  }
}

export default CustomTooltipOfficers;
