import React, { Component } from "react";

import "./App.css";
import "../node_modules/react-vis/dist/style.css";
//import {VerticalGridLines, LineSeries, HorizontalGridLines,XAxis,YAxis,XYPlot} from 'react-vis';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ZAxis,
  CartesianGrid,
  Tooltip,
  Label,
  ScatterChart,
  Scatter,
  Legend,
  LineChart,
  Line,
  ReferenceLine
} from "recharts";
import incidentData from "./incidentData.json";
import ReactGA from "react-ga";
import CustomTooltip from "./CustomTooltip.js";
import CustomTooltipOfficers from "./CustomTooltipOfficers.js";
import CustomXlabel from "./CustomXlabel.js";
import NavBar from "./navbar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.min.css";
import { Multiselect } from "multiselect-react-dropdown";


ReactGA.initialize("UA-166020619-1");
ReactGA.pageview(window.location.pathname + window.location.search);
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      races: [],
      total: 0,
      min: null,
      max: null,
      startDate: null,
      endDate: null,
      activePage: "Incidents",
      officers: [],
      officerData: [],
      dates: [],
      selectedList: "All",
      genderSelectedList: [
        { name: "Male", id: "Male" },
        { name: "Female", id: "Female" },
      ],
      genderOptions: [
        { name: "Male", id: "Male" },
        { name: "Female", id: "Female" },
      ],
    };
  }

  async componentDidMount() {
    await this.calculateData();
  }


  getFormattedDate(date) {
    if (date) {
      let dDate = new Date(date);
      var year = dDate.getFullYear();

      var month = (1 + dDate.getMonth()).toString();
      month = month.length > 1 ? month : "0" + month;

      var day = dDate.getDate().toString();
      day = day.length > 1 ? day : "0" + day;
      return month + "/" + day + "/" + year;
    }
  }
  generateDates(d0,d1) {
    let dates = [];
    dates[0]=d0;
    let end = d1;
    let day = 60 * 60 * 24 * 1000;
    while(dates[dates.length-1]<end){
      let date = new Date()
      dates.push(new Date(date.setTime(dates[dates.length-1].getTime()+day)));
    }
    return dates;

  }


  async calculateData() {
    let races = {};
    let found = {};
    let total = 0;
    let max = new Date();
    let min = new Date();
    let officerData = {};
    let violentLevel = {};
    let raceOptions = {};
    let uniqueIncidents ={};
    let precinctOptions = {};
    let dates = {};
    incidentData.forEach((i) => {
      let date = new Date(i.Occured_date_time);
      if (
        !found[i.Occured_date_time] &&
        this.validDate(date) &&
        this.selected(i.Incident_Type) &&
        this.selectedRaces(i.Subject_Race) &&
        this.selectedGenders(i.Subject_Gender) &&
        this.selectedPrecinct(i.Sector)
      ) {
        violentLevel[i.Incident_Type] = true;
        if (!officerData[i.Officer_ID]) {
          officerData[i.Officer_ID] = 0;
        }
        raceOptions[i.Subject_Race] = {
          name: i.Subject_Race,
          id: i.Subject_Race,
        };
        precinctOptions[i.Sector] = { name: i.Sector, id: i.Sector };
        violentLevel[i.Incident_Type] = true;
        if (!officerData[i.Officer_ID]) {
          officerData[i.Officer_ID] = 0;
        }
        officerData[i.Officer_ID] = officerData[i.Officer_ID] + 1;
        if (!races[i.Subject_Race]) {
          races[i.Subject_Race] = { total: 0, Male: 0, Female: 0, officersInvolved: 0 };
        }
        if(!uniqueIncidents[  this.getFormattedDate(date)+'-'+i.Subject_ID]){

        races[i.Subject_Race][i.Subject_Gender] =
        races[i.Subject_Race][i.Subject_Gender] + 1;
        races[i.Subject_Race].total = races[i.Subject_Race].total + 1;
        races[i.Subject_Race].officersInvolved = races[i.Subject_Race].officersInvolved +1;
        found[i.Occured_date_time] = true;
        total = total + 1;
        if(!dates[this.getFormattedDate(date)]){
          dates[this.getFormattedDate(date)] = 0;
        }
        dates[this.getFormattedDate(date)]=dates[this.getFormattedDate(date)]+1;
        }else{
          races[i.Subject_Race].officersInvolved = races[i.Subject_Race].officersInvolved +1;
          
          
        }
        if (date < min) {
          min = date;
        }
        if (date > max) {
          max = date;
        }
      }
      
      uniqueIncidents[  this.getFormattedDate(date)+'-'+i.Subject_ID]=true;
    });
    let raceData = Object.keys(races)
      .map((v) => ({
        [`${v}_total`]: races[v].total,
        [`${v}_Male`]: races[v].Male,
        [`${v}_Female`]: races[v].Female,
        averageOfficers: (races[v].officersInvolved/races[v].total).toFixed(2),
        Subject_Race:v,
      }))
      .sort((a, b) => {
        return Object.values(b)[0] - Object.values(a)[0];
      });
    let incidentCount = {};

    Object.values(officerData).forEach((o) => {
      if (!incidentCount[o]) {
        incidentCount[o] = { x: o, y: 0 };
      }
      incidentCount[o].y = incidentCount[o].y + 1;
    });
    let fullDates = this.generateDates(min,max);
    fullDates.sort((a,b)=>a-b);
    fullDates = fullDates.map(v=>({date:v, incidents:dates[this.getFormattedDate(v)] ||0 }));
    await this.setState({
      races: Object.keys(races),
      data: raceData,
      total,
      startDate: min,
      endDate:max,
      officers: Object.keys(officerData),
      officerData: Object.values(incidentCount),
      officerDataRaw: Object.values(officerData),
      dates: fullDates,
      min: !this.state.min ? min : this.state.min,
      max:!this.state.max ? max : this.state.max,
      options: !this.state.options
        ? Object.keys(violentLevel).map((v) => ({ name: v, id: v }))
        : this.state.options,
      selectedList: !Array.isArray(this.state.selectedList)
        ? Object.keys(violentLevel).map((v) => ({ name: v, id: v }))
        : this.state.selectedList,
      raceOptions: !Array.isArray(this.state.raceOptions)
        ? Object.values(raceOptions)
        : this.state.raceOptions,
      raceSelectedList: !Array.isArray(this.state.selectedList)
        ? Object.values(raceOptions)
        : this.state.raceSelectedList,
      precinctSelectedList: !Array.isArray(this.state.precinctSelectedList)
        ? Object.values(precinctOptions)
        : this.state.precinctSelectedList,
      precinctOptions: !Array.isArray(this.state.precinctOptions)
        ? Object.values(precinctOptions)
        : this.state.precinctOptions,
    });
  }

  validDate(date) {
    return (
      (!this.state.startDate || this.state.startDate <= date) &&
      (!this.state.endDate || this.state.endDate >= date)
    );
  }
  selected(incidentType) {
    return (
      this.state.selectedList === "All" ||
      this.state.selectedList.map((v) => v.name).includes(incidentType)
    );
  }
  selectedRaces(race) {
    return (
      !this.state.raceSelectedList ||
      this.state.raceSelectedList.map((v) => v.name).includes(race)
    );
  }
  selectedPrecinct(precinct) {
    return (
      !this.state.precinctSelectedList ||
      this.state.precinctSelectedList.map((v) => v.name).includes(precinct)
    );
  }
  selectedGenders(gender) {
    return this.state.genderSelectedList.map((v) => v.name).includes(gender);
  }

  async onSelect(selectedList, selectedItem) {
    await this.setState({ selectedList });
    await this.calculateData();
  }

  async onRemove(selectedList) {
    await this.setState({ selectedList });
    await this.calculateData();
  }
  async onSelectRace(selectedList, selectedItem) {
    await this.setState({ raceSelected: selectedList });
    await this.calculateData();
  }

  async onRemoveRace(selectedList) {
    await this.setState({ raceSelected: selectedList });
    await this.calculateData();
  }
  async onSelectGender(selectedList, selectedItem) {
    await this.setState({ genderSelectedList: selectedList });
    await this.calculateData();
  }

  async onRemoveGender(selectedList) {
    await this.setState({ genderSelectedList: selectedList });
    await this.calculateData();
  }

  async onSelectPrecinct(selectedList, selectedItem) {
    await this.setState({ PrecinctSelectedList: selectedList });
    await this.calculateData();
  }

  async onRemovePrecinct(selectedList) {
    await this.setState({ PrecinctSelectedList: selectedList });
    await this.calculateData();
  }

  async handleChange(e) {
    let value = e.target.value;
    if (e.target.max && +e.target.max < value) {
      value = e.target.max;
    }
    // if(!isNaN(e.target.min) && !isNaN(value) && value<e.target.min){
    //     value = e.target.min;
    // }
    await this.setState({ [e.target.name]: value });
    await this.calcProjections();
  }

  async setStartDate(date) {
    await this.setState({ startDate: date });
    await this.calculateData();
  }

  async setEndDate(date) {
    await this.setState({ endDate: date });
    await this.calculateData();
  }

  changePage(e) {
    this.setState({ activePage: e.target.innerHTML });
  }

  dateToYMD(date) {
    var d = date.getDate();
    var m = date.getMonth() + 1; //Month from 0 to 11
    var y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }

  getDateDifference(date1, date2) {
    let differenceInTime =
      new Date(date2.setHours(0, 0, 0, 0)).getTime() -
      new Date(date1.setHours(0, 0, 0, 0)).getTime();
    // To calculate the no. of days between two dates
    return differenceInTime / (1000 * 3600 * 24);
  }

  calcLegend() {
    let payload = [];
    if(this.selectedGenders('Male')){
      payload.push( {color:"#156079",
      inactive: false,
      type: "rect",
      value: "Male"})
    }
    if(this.selectedGenders('Female')){

      payload.push({color:"#ffb55f",
      inactive: false,
      type: "rect",
      value: "Female"})
    }
    return payload;
  }


  render() {
    let { startDate, endDate, min, max } = this.state;
    return (
      <>
        <h1 style={{ textAlign: "center", marginBottom: "5px" }}>
          Incident Data
        </h1>
        <h3 style={{ textAlign: "center", marginTop: "0px" }}>{this.getFormattedDate(startDate)}-{this.getFormattedDate(endDate)}</h3>
        <NavBar
          active={this.state.activePage}
          onClick={(e) => this.changePage(e)}
        />

        {this.state.activePage === "Incidents" && (
          <ResponsiveContainer width="95%" height={550}>
            <BarChart
              data={this.state.data}
              margin={{ top: 20, right: 50, bottom: 0, left: 50 }}
            >
              <CartesianGrid stroke="#ccc" />

              <Tooltip content={<CustomTooltip total={this.state.total} />} />
              {this.state.races.map((r) => {
                return (
                  <Bar
                    name={"Male"}
                    stackId="a"
                    dataKey={`${r}_Male`}
                    fill={`#156079`}
                  />
                );
              })}
              {this.state.races.map((r) => {
                return (
                  <Bar
                    name={"Female"}
                    stackId="a"
                    dataKey={`${r}_Female`}
                    fill={`#ffb55f`}
                  />
                );
              })}
              <XAxis  
              dataKey="Subject_Race" 
              height={100}  
              interval={0}
              tick={<CustomXlabel />}
              />

              <YAxis />
              <Legend
              payload={this.calcLegend()}
            />
            </BarChart>
          </ResponsiveContainer>
        )}

        {this.state.activePage === "Officers" && (
          <ResponsiveContainer width="95%" height={550}>
            <ScatterChart
              width={400}
              height={400}
              margin={{ top: 20, right: 50, bottom: 50, left: 30 }}
            >
              <Tooltip
                content={
                  <CustomTooltipOfficers
                    officerDataRaw={this.state.officerDataRaw}
                  />
                }
              />
              <CartesianGrid />
              <XAxis
                type="number"
                dataKey="x"
                name="Use Of Force"
                label={{
                  value: "Use Of Force Incidents",
                  position: "insideMiddle",
                  dy: 30,
                }}
              />
              <YAxis type="number" dataKey="y" name="Number Of Officers">
                <Label
                  angle={-90}
                  value="Number of Officers"
                  position="left"
                  offset={20}
                  style={{ textAnchor: "middle", paddingLeft: "20px" }}
                />
              </YAxis>
              <ZAxis type="number" dataKey="z" range={[50, 300]} />
              <Tooltip cursor={{ strokeDasharray: "3 3" }} />
              <Scatter
                name="A school"
                data={this.state.officerData}
                fill="#8884d8"
              />
            </ScatterChart>
          </ResponsiveContainer>
        )}
        {
          this.state.activePage ==='Date' &&
          <ResponsiveContainer width="95%" height={550}>
          <LineChart
            data={this.state.dates.sort((a,b)=>a-b)}
            margin={{ top: 20, right: 50, bottom: 0, left: 50 }}
          >
            <Line
                  name={`Daily Unique Incidents`}
                  type="monotone"
                  dataKey={'incidents'}
                  stroke="#4a4a4a"
                  activeDot={{ r: 8 }}
                  key='date'
                />
            <Tooltip
              itemSorter={(item) => item.name}
              formatter={(value, name, props) => value.toLocaleString()}
              labelFormatter={(v) => {
                let d = new Date(
                  this.state.dates[ v] ?this.state.dates[ v].date : ""
                );
                let d2 = new Date(this.state.maxDate);
                d2 = new Date(d2.setMinutes(d2.getMinutes() + d2.getTimezoneOffset()));
               
                return (
                  <div>
                    {d >d2 && (
                      <>{"Projection For: "}</>
                    )}
                    {this.getFormattedDate(d)}
                  </div>
                );
              }}
            />
            <CartesianGrid stroke="#ccc" />
            <Tooltip/>
            <XAxis
              tickCount={7}
              tickFormatter={(tick) => {
                let d = new Date(
                  this.state.dates[tick] ? this.state.dates[tick].date : ""
                );
                return this.getFormattedDate(d);
              }}
              
            />
            <YAxis tickFormatter={(tick) => tick.toLocaleString()} />
            <ReferenceLine
              x={Math.round(
                this.getDateDifference(
                  new Date(this.state.dates[0].date),
                  new Date('08/17/2018')
                )
              )}
              stroke="green"
              strokeWidth={2}
              label={{
                position: "top",
                value: 'Best named Chief',
                fill: "green",
                fontSize: 12,
              }}
            />
             <ReferenceLine
              x={Math.round(
                this.getDateDifference(
                  new Date(this.state.dates[0].date),
                  new Date('12/31/2017')
                )
              )}
              stroke="red"
              strokeWidth={2}
              label={{
                position: "insideTop",
                value: 'Best named Interim Chief',
                fill: "red",
                fontSize: 12,
              }}
            />
          </LineChart>
        </ResponsiveContainer>
        }
        <br />
        <h3
            style={{
              textAlign: "left",
              marginRight: "20px",
              marginLeft: "30px",
              marginTop: '-20px',
              marginBottom: '-10px'
            }}
          >
            Filter Data
          </h3>
          <br />
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              margin:'10px',
              paddingLeft:'20px'
            }}
          >
            Start Date: 
          <div  style={{
              marginLight:'5px',
              marginRight:'10px'
            }}>
          <DatePicker
            selected={startDate}
            onChange={(date) => this.setStartDate(date)}
            minDate={min}
            maxDate={max}
            selectsStart
            showYearDropdown
            startDate={startDate}
            endDate={endDate}
          />
          </div>
          End Date: 
          <div  style={{
              marginLight:'5px',
              marginRight:'10px'
            }}>
          <DatePicker
            selected={endDate}
            onChange={(date) => this.setEndDate(date)}
            minDate={min}
            maxDate={max}
            selectsEnd
            showYearDropdown
            startDate={startDate}
            endDate={endDate}
          />
           </div>
          </div>
       
        <br />
        <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              margin:'10px'
            }}
          >
            <span
              
              style={{
                minWidth: '100px',
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              Levels of force:
            </span>
            <Multiselect
              style={{
                minWidth: '30%',
                maxWidth: '60%',
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
              options={this.state.options} // Options to display in the dropdown
              selectedValues={this.state.selectedList} // Preselected value to persist in dropdown
              onSelect={(selectedList, selectedItem) =>
                this.onSelect(selectedList, selectedItem)
              } // Function will trigger on select event
              onRemove={(selectedList, removedItem) =>
                this.onRemove(selectedList, removedItem)
              } // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
       
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              margin:'10px'
            }}
          >
            <span
              
              style={{
                minWidth: '100px',
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              Demographics:
            </span>
            <Multiselect
              style={{
                minWidth: '30%',
                maxWidth: '60%',
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
              options={this.state.raceOptions} // Options to display in the dropdown
              selectedValues={this.state.raceSelectedList} // Preselected value to persist in dropdown
              onSelect={(selectedList, selectedItem) =>
                this.onSelectRace(selectedList, selectedItem)
              } // Function will trigger on select event
              onRemove={(selectedList, removedItem) =>
                this.onRemoveRace(selectedList, removedItem)
              } // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              margin:'10px'
            }}
          >
            <span
              
              style={{
                minWidth: '100px',
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              genders:
            </span>
            <Multiselect
             style={{
              minWidth: '30%',
              maxWidth: '60%',
              textAlign: "left",
              marginTop: "10px",
              marginRight: "20px",
              marginLeft: "20px",
            }}
          options={this.state.genderOptions} // Options to display in the dropdown
          selectedValues={this.state.genderSelectedList} // Preselected value to persist in dropdown
          onSelect={(selectedList, selectedItem) =>
            this.onSelectGender(selectedList, selectedItem)
          } // Function will trigger on select event
          onRemove={(selectedList, removedItem) =>
            this.onRemoveGender(selectedList, removedItem)
          } // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />
          </div>
          {/* <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              margin:'10px'
            }}
          >
            <span
              
              style={{
                minWidth: '100px',
                textAlign: "left",
                marginTop: "10px",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              Sector:
            </span>
            <Multiselect
            style={{
              minWidth: '30%',
              maxWidth: '60%',
              textAlign: "left",
              marginTop: "10px",
              marginRight: "20px",
              marginLeft: "20px",
            }}
          options={this.state.precinctOptions} // Options to display in the dropdown
          selectedValues={this.state.precinctSelectedList} // Preselected value to persist in dropdown
          onSelect={(selectedList, selectedItem) =>
            this.onSelectPrecinct(selectedList, selectedItem)
          } // Function will trigger on select event
          onRemove={(selectedList, removedItem) =>
            this.onRemovePrecinct(selectedList, removedItem)
          } // Function will trigger on remove event
          displayValue="name" // Property name to display in the dropdown options
        />
          </div> */}
      
        
       
      </>
    );
  }
}

export default App;
