// src/components/NavBar.js

import React from "react";

const NavBar = ({ active, onClick }) => {
  return (
    <div style={{ marginLeft: "0px", marginRight: "0px" }}>
      <span
        style={
          active === "Incidents"
            ? {
                border: "1px solid #ccc",
                fontWeight: "500",
                color: "black",
                width: "60px",
                marginLeft: "2%",
                textDecoration: "none",
                cursor: "pointer",
                paddingRight: "5%",
                paddingLeft: "5%",
                borderRadius: "5px",
              }
            : {
                width: "60px",
                marginLeft: "2%",
                color: "#4a4a4a",
                textDecoration: "none",
                cursor: "pointer",
                border: "1px solid #ccc",
                paddingRight: "5%",
                paddingLeft: "5%",
                borderRadius: "5px",
              }
        }
        onClick={onClick}
        //to="/"
      >
        Incidents
      </span>
      <span
        style={
          active === "Officers"
            ? {
                fontWeight: "500",
                color: "black",
                width: "120px",
                marginLeft: "2%",
                textDecoration: "none",
                cursor: "pointer",
                border: "1px solid #ccc",
                paddingRight: "5%",
                paddingLeft: "5%",
                borderRadius: "5px",
              }
            : {
                width: "120px",
                marginLeft: "2%",
                color: "#4a4a4a",
                textDecoration: "none",
                cursor: "pointer",
                border: "1px solid #ccc",
                paddingRight: "5%",
                paddingLeft: "5%",
                borderRadius: "5px",
              }
        }
        onClick={onClick}
        //to="/"
      >
        Officers
      </span>
      <span
        style={
          active === "Date"
            ? {
                fontWeight: "500",
                color: "black",
                width: "60px",
                marginLeft: "2%",
                textDecoration: "none",
                cursor: "pointer",
                border: "1px solid #ccc",
                paddingRight: "5%",
                paddingLeft: "5%",
                borderRadius: "5px",
              }
            : {
                width: "60px",
                marginLeft: "2%",
                color: "#4a4a4a",
                textDecoration: "none",
                cursor: "pointer",
                border: "1px solid #ccc",
                paddingRight: "5%",
                paddingLeft: "5%",
                borderRadius: "5px",
              }
        }
        onClick={onClick}
        //to="/"
      >
        Date
      </span>
    </div>
  );
};

export default NavBar;
